<template>
  <main id="app">
    <section>
      <h1>"GiftIdeas" Artificial Intelligence Is Here To Help You Find The Perfect Gift!</h1>
    </section>

    <h2>What is GiftIdeas?</h2>
    <p>GiftIdeas is a chat bot to help you find the perfect gift!</p>
    <p>If you're here, you're probably afraid of disappointing the other person. You don't want the person to throw away your gift or sell it on eBay literally 1 or 2 weeks after you gave it (although this is in fact a very, VERY common behavior unfortunately!).</p>

    <p>Well we've got good news. You can easily generate a dozen ideas with GiftIdeas here to help you!</p>
    <p></p>

    <h2>Does GiftIdeas cost anything to use?</h2>
    <p>Nope.</p>

    <h2>Why doesn't GiftIdeas cost anything?</h2>
    <p>We kind of built this project for fun to see what the hype around GPT-3 was all about.</p>

    <h2>What do we think about GPT-3 as a result of this small chat bot project?</h2>
    <p>
      We don't think GPT-3 should be used for chat bots. There are plenty of other commercial chat bots you can try out that actually seem pretty darn human. There was one particular chat bot we tested by just asking, "What is 1+1" and the chat bot spit back "2?". The chat bot was for an entirely different purpose.
    </p>

    <p>The true novelty of GPT-3 comes from much harder unsolved problems that now have a tractable solution. You might have seen some of them covered in the press already. Here are a couple examples that are truly revolutionary breakthroughs:</p>

    <ul>
      <li>Code generation (something like generating React code). Code generation that...actually works and compiles.</li>
      <li>Semantic Search. A cool example of this is giving it a block of text and then asking questions about it, like "Who is the protagonist?".</li>
      <li>Translations. You ask ask GPT-3: what is the meaning of "Bonjour"?</li>
    </ul>

    <p>Another big downside is the pretty big cost of using GPT-3 for chat bots. The cost is something like O(n^2) where n is the number of messages. For each new message you send to GiftIdeas, we have to paste that entire conversation into OpenAI's API. It's not incremental. So you can see the costs start to pile up...the math is something like: 1+2+3+4... = n*(n-1)/2. OpenAI bills you by the number of tokens used for input and output (a token being roughly a word, as a good rule of thumb).</p>
    <p></p>

    <h2>How did we build GiftIdeas?</h2>
    <p>This uses GPT-3 as a chatbot. We used the Python SDK. We built the frontend using Vue (shoutout to Vue for being an insanely great framework. In our opinion Vue will overtake React). Then we just went with PHP as backend. It's just easiest to setup.</p>

    <h2>When should you use GiftIdeas?</h2>
    <p>The holidays are pretty good time. Valentine's day. Any social events like birthdays or school dances.</p>

    <h2>Where is the team located?</h2>
    <p>Texas.</p>

    <h2>Why build GiftIdeas?</h2>
    <p>Honestly we built this Chat Bot for fun. We wanted to play around with GPT-3 and see what the hype was all about. It was pretty cool to see how little code was actually written to accomplish something so powerful.</p>

    <p>That said, after further investigation into chat bot technology, it turns out chat bots are kind of a solved problem? There are many commercially available chat bots that do a pretty dang good job of seeming human</p>

    <p>It was still fun to built GiftIdeas though. We don't really see a clear path to profitability on this, it's literally just for fun.</p>

    <!-- <h2>Who</h2> -->
    <!-- <p>filler</p> -->

    <h2>How to contact us?</h2>
    <p>Subscribe to our email list (see sticky at the bottom). Or email us: contact@giftideas.gift</p>

    <h2>Are we making money off GiftIdeas?</h2>
    <p>Nope. We're just doing this for fun. We may release other GPT-3 projects. Are you interested in following our projects? Subscribe to our mailing list (see sticky at the bottom).</p>

    <beautiful-chat
      :participants="participants"
      :titleImageUrl="titleImageUrl"
      :onMessageWasSent="onMessageWasSent"
      :messageList="messageList"
      :newMessagesCount="newMessagesCount"
      :isOpen="isChatOpen"
      :close="closeChat"
      :open="openChat"
      :showEmoji="false"
      :showFile="false"
      :showEdition="true"
      :showDeletion="true"
      :deletionConfirmation="true"
      :showTypingIndicator="showTypingIndicator"
      :showLauncher="true"
      :showCloseButton="true"
      :colors="colors"
      :alwaysScrollToBottom="alwaysScrollToBottom"
      :disableUserListToggle="false"
      :messageStyling="messageStyling"
      @onType="handleOnType"
      @edit="editMessage" />
  </main>
</template>

<script>
function post(data, callback) {
  var request = new XMLHttpRequest();
  if (window.location.hostname === 'localhost') {
    request.open('POST', 'http://localhost:91/proxy.php?bot=giftideas', true);
  } else {
    request.open('POST', 'proxy.php?bot=giftideas', true);
  }

  request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
  request.onload = function() {
    if (this.status >= 200 && this.status < 400) {
      //console.log('reply', this.response);
      var data = JSON.parse(this.response);
      callback(data);
    } else {
      // We reached our target server, but it returned an error
    }
  };
  request.send(JSON.stringify(data));
}

export default {
  name: 'App',
  data() {
    return {
      // the list of all the participant of the conversation.
      // `name` is the user name
      // `id` is used to establish the author of a message
      // `imageUrl` is supposed to be the user avatar.
      participants: [
        {
          id: 'user1',
          name: 'GiftIdeas',
          imageUrl: 'https://avatars3.githubusercontent.com/u/1915989?s=230&v=4'
        }
      ], 
      titleImageUrl: '/favicon.ico',
      messageList: [
          { type: 'text', author: `GiftIdeas`, data: { text: `Hello! Who are you buying a gift for?` } },
      ], // the list of the messages to show, can be paginated and adjusted dynamically
      newMessagesCount: 0,
      isChatOpen: true, // to determine whether the chat window should be open or closed
      showTypingIndicator: '', // when set to a value matching the participant.id it shows the typing indicator for the specific user
      colors: {
        header: {
          bg: '#4e8cff',
          text: '#ffffff'
        },
        launcher: {
          bg: '#4e8cff'
        },
        messageList: {
          bg: '#ffffff'
        },
        sentMessage: {
          bg: '#4e8cff',
          text: '#ffffff'
        },
        receivedMessage: {
          bg: '#eaeaea',
          text: '#222222'
        },
        userInput: {
          bg: '#f4f7f9',
          text: '#565867'
        }
      }, // specifies the color scheme for the component
      alwaysScrollToBottom: false, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
      messageStyling: true // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)
    }
  },
  methods: {
    sendMessage (text) {
      if (text.length > 0) {
        this.newMessagesCount = this.isChatOpen ? this.newMessagesCount : this.newMessagesCount + 1
        this.onMessageWasSent({ author: 'support', type: 'text', data: { text } })
      }
    },
    onMessageWasSent (message) {
      // called when the user sends a message
      // this.messageList = [ ...this.messageList, message ]
      this.messageList.push(message)

      console.log('d1', this.messageList);

      var that = this;
      post(this.messageList, function(result) {
        that.messageList.push({
          type: 'text',
          author: 'GiftIdeas',
          data: {
            text: result.answer,
          },
        })
      })
    },
    openChat () {
      // called when the user clicks on the fab button to open the chat
      this.isChatOpen = true
      this.newMessagesCount = 0
    },
    closeChat () {
      // called when the user clicks on the botton to close the chat
      this.isChatOpen = false
    },
    handleScrollToTop () {
      // called when the user scrolls message list to top
      // leverage pagination for loading another page of messages
    },
    handleOnType () {
      console.log('Emit typing event')
    },
    editMessage(message){
      const m = this.messageList.find(m=>m.id === message.id);
      m.isEdited = true;
      m.data.text = message.data.text;
    }
  }
}
</script>

<style>
  /* loader animation */
  @-webkit-keyframes spinner-border { to { transform: rotate(360deg) } }
  @keyframes spinner-border { to { transform: rotate(360deg) } }
  .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
  }
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
  }

#one, #two {
  margin: 0px;
}
#l1, #l2 {
  top: 0px;
}
main {
  padding-top: 0px;
  margin-top: 0px;
}
h1, h2 {
  padding: 0px;
  margin: 0px;
}

body {
  background-color: #e9ebee;
}
.formkit-sticky-bar button[data-formkit-close] {
  display: none;
}
</style>
